import axios from "axios";
import {url} from "./server.js";

const createUser = async (email, password, role) => {
  //console.log(localStorage.getItem("token"));
  try {
    const response = await axios.post(`${url}api/admin/createUser`, {
      "email": email,
      "password": password,
      "role":role
    }
    ,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
    }
    );
    //console.log("Response from createUser:", response);
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    return null;
  }
};

export default createUser;
