import React, { useState, useEffect } from "react";
import ScoreBoard from "../../components/agents/ScoreBoard/ScoreBoard";
import ScoreTable from "../../components/agents/ScoreTable/ScoreTable";
import CallsTable from "../../components/calls/CallsTable";

const ScoreLayout = ({ prop }) => {
  const [refresh, setRefresh] = useState(false);
  
  useEffect(() => {
    // Set interval to refresh every 5 minutes
    const interval = setInterval(() => {
      setRefresh(true);
    }, 5 * 60 * 1000); // 5 minutes
    
    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="flex flex-row justify-center h-screen overflow-hidden">
        <div className="items-center p-8 mt-12 ml-10">
          <ScoreBoard />
        </div>
        <div className="flex-1 mr-10">
          {/* {refresh ? <ScoreTable /> : (prop && 1 ? prop : <ScoreTable />)} */}
          {refresh ? <CallsTable/> : (prop && 1 ? prop : <CallsTable />)}
        </div>
      </div>
    </>
  );
};

export default ScoreLayout;
