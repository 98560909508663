import { useEffect, useState } from "react";
import axios from "axios";

const CallsTable = () => {
  const [calls, setCalls] = useState([]);

  const colourCode = (rating) => {
    if (rating >= 4) return "text-green-500";
    if ( rating === 3) return "text-orange-500";
    if (rating >= 0 && rating < 3 ) return "text-red-500";
    return "text-gray-500";
  };

  const colourCodeDelay = (delay) => {
    if (delay >= 8) return "text-red-500";
    if (delay >= 5 && delay < 8) return "text-orange-500";
    if (delay >= 0 && delay < 5 ) return "text-green-500";
    return "text-gray-500";
  };

  const fetchCalls = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_SERVER}api/admin/calls`
      );
      const data = response.data.data;
      setCalls(data);
    } catch (error) {
      console.error("Error fetching calls:", error);
    }
  };

  useEffect(() => {
    fetchCalls();
  }, []);

  return (
    <>
      <div className="mt-20 ml-10 mr-10 overflow-y-auto text-sm " style={{height: "700px" }}>
        <table className="table h-56 overflow-y-scroll bg-white table-pin-rows ">
          <thead className="text-center ">
            <tr>
              <th className="text-sm">Date</th>
              <th className="text-sm">Time</th>
              <th className="text-sm">Agent</th>
              <th className="text-sm">Survey Rating</th>
              <th className="text-sm">Answer Delay</th>
              <th className="text-sm">Call Id </th>
            </tr>
          </thead>
          <tbody className="text-center">
            {calls?.map((item, index) => (
              <tr className="h-16" key={index}>
                <td className="w-60">{item.call_timestamp?.split('T')[0]} </td>
                <td className="w-60">{item.call_timestamp?.split('T')[1]?.split('.')[0]}</td>
                <td className="w-60">{item.agent}</td>
                <td className={`w-60 font-semibold text-lg ${colourCode(item.rating)}`}>{item.rating}</td>
                <td className={`w-60 font-semibold text-lg ${colourCodeDelay(item.answering_speed)}`}>{item.answering_speed} Secs</td>
                <td className="w-60">{item.call_id}</td>            
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CallsTable;
