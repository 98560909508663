import axios from "axios";
import {url} from "./server.js";

const fetchScore = async () => {

  //console.log(localStorage.getItem("token"));
  try {
    const response = await axios.get(`${url}api/scores`,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
    }
    );
    //console.log("Response from fetchUser:", response);
    return response.data.data;
  } catch (error) {
    console.error("Error creating user:", error);
    return null;
  }
};

export default fetchScore;
