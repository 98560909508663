import { useEffect, useState, useContext } from "react";
import AlertContext from "../../../globals/AlertContext";
import createUser from "../../../utils/createUser";
import fetchUser from "../../../utils/fetchUser";

const AgentTable = () => {
  const [agents, setAgents] = useState([]);
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [roleInput, setRoleInput] = useState("");
  const { setAlert } = useContext(AlertContext);

  const handleAgent = async () => {
    await createUser(emailInput, passwordInput, roleInput);
    setAlert("Agent added successfully");
  };

  const fetchAgents = async () => {
    try {
      const response = await fetchUser();
      const data = response;
      //console.log("data", data);
      if (!data) {
        throw new Error("No data found");
      }
      setAgents(data);
    } catch (error) {
      console.error("Error fetching agents:", error);
      setAlert("No data found");
    }
  };

  const convertDate = (timestamp) => {
    const milliseconds =
      timestamp._seconds * 1000 + timestamp._nanoseconds / 1e6;
    const date = new Date(milliseconds);
    return date.toLocaleDateString();
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  return (
    <>
      <div className="p-10 mt-20 overflow-x-auto ">
        <table className="table bg-white table-pin-rows">
          <thead className="text-center">
            <tr>
              {/* <th className="text-sm">Freshdesk ID</th> */}
              <th className="text-sm">Email / UserName</th>
              <th className="text-sm">Password</th>
              <th className="text-sm">Role</th>
              <th className="text-sm">Action</th>
            </tr>
            <tr className="text-center">
              <th className="w-40 text-sm">
                <input
                  type="email"
                  className="w-40 h-8 text-xs text-center"
                  placeholder="Email"
                  value={emailInput}
                  onChange={(e) => setEmailInput(e.target.value)}
                />
              </th>
              <th className="text-sm w-30">
                <input
                  type="password"
                  className="h-8 text-xs text-center w-30"
                  placeholder="Password"
                  value={passwordInput}
                  onChange={(e) => setPasswordInput(e.target.value)}
                />
              </th>
              <th className="text-sm">
                <input
                  type="text"
                  className="w-20 h-8 text-xs text-center"
                  placeholder="admin/agent"
                  value={roleInput}
                  onChange={(e) => setRoleInput(e.target.value)}
                />
              </th>
              <th className="text-sm">
                <button
                  className="h-8 text-xs btn btn-xs"
                  style={{
                    backgroundColor: "#308EFE",
                    color: "white",
                    borderRadius: "5px",
                  }}
                  onClick={handleAgent}
                >
                  Add User
                </button>
              </th>
            </tr>
          </thead>
        </table>

        <table className="table mt-10 bg-white table-pin-rows">
          <thead className="text-center">
            <tr>
              <th className="text-sm">Joining Date</th>
              <th className="text-sm">Email / UserName</th>
              <th className="text-sm">Role</th>
              <th className="text-sm">Action</th>
            </tr>
          </thead>

          <tbody className="text-center">
            {agents.map((item, index) => (
              <tr key={index}>
                <td>{convertDate(item.joiningDate)} </td>
                <td>{item.email}</td>
                <td>{item.role}</td>
                <td>
                  <button
                    className="text-xs btn btn-xs bg-slate-100"
                    onClick={() => handleAgent(index)}
                  >
                    Remove User
                  </button>
                </td>
                <td>{item.agentStatus}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AgentTable;
