import Sidebar from "../../components/sidebar/Sidebar";
import "../layout/AppLayout.scss";
import SearchLabel from "../../components/labels/SearchLabel";

const LabelsLayout = () => {
  return (

      <div className="Screen__sidebar_content">
        <SearchLabel />
      </div>

  );
};

export default LabelsLayout;
