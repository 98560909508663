import axios from "axios";
import { url } from "./server.js";

const mailPayment = async (body) => {
  //console.log(localStorage.getItem("token"));
  try {
    const response = await axios.post(
      `${url}api/admin/mail/payments`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body,
      }
    );
    //console.log("Response from fetchUser:", response);
    return response.data.data;
  } catch (error) {
  
    return null;
  }
};

export default mailPayment;
