import React, { useState } from 'react';

import './ShipmentForm.scss';

const ShipmentForm = () => {
  const [courierID, setCourierID] = useState('');
  const [shippingAddress, setShippingAddress] = useState({
    line_1: '',
    line_2: '',
    city: '',
    state: '',
    postal_code: '',
    country_alpha2: 'US',
  });
  const [qnty1, setQnty1] = useState(0);
  const [qnty2, setQnty2] = useState(0);
  const [orderNumber, setOrderNumber] = useState('');
  const [response, setResponse] = useState(null);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const shipmentData = {
        courier_id: courierID,
        shipping_address: shippingAddress,
        qnty1,
        qnty2,
        orderNumber,
      };

      // const response = await createShipment(shipmentData);
      // setResponse(response);
    } catch (error) {
      console.error(error);
      // Handle error response
    }
  };

  return (
    <div className="shipment">
      <h1>Create Shipment</h1>
      <form onSubmit={handleFormSubmit}>
        <div>
          <label>Courier ID:</label>
          <input
            type="text"
            value={courierID}
            onChange={(e) => setCourierID(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Shipping Address Line 1:</label>
          <input
            type="text"
            value={shippingAddress.line_1}
            onChange={(e) =>
              setShippingAddress({
                ...shippingAddress,
                line_1: e.target.value,
              })
            }
            required
          />
        </div>
        <div>
          <label>Shipping Address Line 2:</label>
          <input
            type="text"
            value={shippingAddress.line_2}
            onChange={(e) =>
              setShippingAddress({
                ...shippingAddress,
                line_2: e.target.value,
              })
            }
          />
        </div>
        <div>
          <label>City:</label>
          <input
            type="text"
            value={shippingAddress.city}
            onChange={(e) =>
              setShippingAddress({
                ...shippingAddress,
                city: e.target.value,
              })
            }
            required
          />
        </div>
        <div>
          <label>State:</label>
          <input
            type="text"
            value={shippingAddress.state}
            onChange={(e) =>
              setShippingAddress({
                ...shippingAddress,
                state: e.target.value,
              })
            }
            required
          />
        </div>
        <div>
          <label>Postal Code:</label>
          <input
            type="text"
            value={shippingAddress.postal_code}
            onChange={(e) =>
              setShippingAddress({
                ...shippingAddress,
                postal_code: e.target.value,
              })
            }
            required
          />
        </div>
        <div>
          <label>Quantity 1:</label>
          <input
            type="number"
            value={qnty1}
            onChange={(e) => setQnty1(parseInt(e.target.value))}
            required
          />
        </div>
        <div>
        <label>Quantity 2:</label>
          <input
            type="number"
            value={qnty2}
            onChange={(e) => setQnty2(parseInt(e.target.value))}
            required
          />
        </div>
        <div>
          <label>Order Number:</label>
          <input
            type="text"
            value={orderNumber}
            onChange={(e) => setOrderNumber(e.target.value)}
            required
          />
        </div>
        <button type="submit">Create Shipment</button>
      </form>

      {response && (
        <div>
          <h2>Response:</h2>
          <p>Easyship Shipment ID: {response.easyship_shipment_id}</p>
          <p>Tracking Page URL: {response.tracking_page_url}</p>
          <p>Courier: {response.courier}</p>
          <p>Rates: {response.rates}</p>
          {/* Display other response data as needed */}
        </div>
      )}
    </div>
  );
};

export default ShipmentForm;
