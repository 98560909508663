// import { useState, useEffect } from "react";
// import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import logo from "../../logo.png";
import "./login.scss";


const Login = ({handleLogin, setPassword, setEmail, password, email, role, setRole}) => {
 

  return (
    <div className="bg">
    <div className="login">
      <div className="login-container">
        {/* <h2>Quick Shaver</h2> */}
        <img className="mb-10 w-60 " src={logo} alt="login" />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
         <input
          type="test"
          placeholder="Enter admin/agent"
          value={role}
          onChange={(e) => setRole(e.target.value)}
        />
        <button onClick={handleLogin}>Login</button>
      </div>
    </div>
    </div>
  );
};



export default Login;
