import React, { createContext, useState } from 'react';

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  

  const setAlert = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
  };


  const closeAlert = () => {
    setShowAlert(false);
  };

  return (
    <AlertContext.Provider value={{ showAlert, alertMessage, setAlert, closeAlert, showLoader , setShowLoader }}>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
