import { url } from "./server.js";
import axios from "axios";


const endpoint =  url + "api/admin/labels";

const fetchLabels = async (startDate, endDate, query) => {
  //console.log(startDate, endDate, query);
  try {
    const response = await axios.post(endpoint, {
      startTime: startDate,
      endTime: endDate,
      querySubstring: query,
    });

    //console.log("response", response.data.data);
    const { data } = response.data;
    return [...data];
  } catch (error) {
    console.error(error);
    // Handle error response
  }
};

export default fetchLabels;
