import React from "react";
import { Navigate } from "react-router-dom";

const Blank = () => {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f1f1f1",
  };

  const contentStyle = {
    textAlign: "center",
  };

  const headingStyle = {
    fontSize: "32px",
    marginBottom: "20px",
  };

  const buttonStyle = {
    padding: "10px 20px",
    backgroundColor: "#ccc",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  };

  const handleBackToHome = () => {
    // Implement the logic to go back to home page
    //console.log("Go back to home");

    return  (<Navigate to="/" />);
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <h1 style={headingStyle}>Coming Soon! Under Development</h1>
        <button style={buttonStyle} onClick={handleBackToHome}>
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default Blank;

