import axios from "axios";
import {url} from "./server.js";

const refundPayment = async (paymentId, amount, ticketId) => {
//   //console.log(localStorage.getItem("token"));
  try {
    const response = await axios.post(`${url}api/admin/refundPayment`, {
      "paymentId": paymentId,
      "amount": amount,
      "ticketId": ticketId
    }
    ,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
    }
    );
    //console.log("Response from createUser:", response);
    return response.data;
  } catch (error) {
    console.error("Error Refund:", error);
    throw error("Error Refund:", error);
  }
};

export default refundPayment;
