import { useEffect, useState, useContext } from "react";
import AlertContext from "../../../globals/AlertContext";
import fetchScore from "../../../utils/fetchScore";
import Bonus from "../bonus";


const ScoreTable = () => {
  const [scores, setScores] = useState([]);
  const { setAlert } = useContext(AlertContext);

  const fetchScores = async () => {
    try {
      const response = await fetchScore();
      const data = response;
      if (data.length === 0) setAlert("No data found");
      setScores(data);
    } catch (error) {
      console.error("Error fetching scores:", error);
    }
  };

  useEffect(() => {
    fetchScores();
  }, []);

  return (
    <>
      <div className="p-10 mt-20 overflow-y-auto ">
        <table className="table bg-white table-pin-rows">
          <thead className="text-center">
            <tr>
              <th className="text-sm"># Rank</th>
              <th className="text-sm">Agent</th>
              <th className="text-sm">Score (LIVE)</th>
              <th className="text-sm">Orders Taken</th>
              <th className="text-sm">Tickets</th>
              <th className="text-sm">Avg. Rating</th>
              <th className="text-sm">$ Bonus</th>
            </tr>
          </thead>
          { (scores.length) ? (
            <tbody className="text-center">
              {scores.map((item, index) => (
                <tr
                  key={index}
                  className={index + 1 === 1 ? "bg-yellow-100 " : ""}
                >
                  <td>{index + 1} </td>
                  <td>{item.agent} </td>
                  <td>{Math.round(item.score)}</td>
                  <td>{item.orders}</td>
                  <td>{item.tickets}</td>
                  <td>{item.rating}</td>
                  <td>${Bonus[index]}/hr</td>
                </tr>
              ))}
            </tbody>
          ) : (
            <>
            <td></td>
            <td></td>
            <td></td>
            <p className="flex items-center justify-center p-3 text-sm text-center"> No Agent found for today !</p>
            </>
          )}
        </table>
      </div>
    </>
  );
};

export default ScoreTable;
