import "./App.scss";
import "boxicons/css/boxicons.min.css";
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppLayout from "./pages/layout/AppLayout";
import Blank from "./pages/Blank";
import VerifyPaymentForm from "./components/verifyPaymentForm/VerifyPaymentForm";
import axios from "axios";
import Logout from "./components/login/Logout";
import ShipmentForm from "./components/shipment/ShipmentForm";
import LabelsLayout from "./pages/labels/LabelsLayout";
import PaymentTable from "./components/payments/PaymentTable";
import ReturnTable from "./components/returns/ReturnTable";
import AlertBox from "./components/alerts/AlertBox";
import LogTable from "./components/logs/LogTable";
import CallsTable from "./components/calls/CallsTable";
import AgentSidebar from "./components/agents/AgentSidebar";
import Login from "./components/login/Login";
import AlertContext from "./globals/AlertContext";
import { url } from "./utils/server.js";
import { useContext } from "react";
import AgentTable from "./components/agents/AgentTable/AgentTable";
import ScoreTable from "./components/agents/ScoreTable/ScoreTable";
import ScoreLayout from "./pages/score/ScoreLayout";
import Loader from "./components/alerts/Loader";

import "./scrollbar.scss";
import OrderTable from "./components/orders/OrderTable";

const AuthUrl = url + "api/admin/";

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const { setAlert } = useContext(AlertContext);

  const handleLogin = async () => {
    try {
      setAlert("Loading...");
      const response = await axios.post(`${AuthUrl}` + `login`, {
        email: email,
        password: password,
        role: role,
      });

      //console.log(response.data);
      if (response.data.status) {
        setLoggedIn(true);
        localStorage.setItem("loggedIn", true);
        localStorage.setItem("token", response.data.token);
        setAlert("Login successful!");
      } else {
        setAlert("Something went wrong..");
      }
    } catch (error) {
      console.error("Login failed:", error);
      setAlert("Something went wrong...");
      // Handle login failure
    }
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("loggedIn");
    if (isLoggedIn) {
      setLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth <= 800); // Set the breakpoint according to your needs
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
        <Route path="/scores" element={<ScoreLayout />} />
          {loggedIn ? (
            <>
              <Route path="/" element={<AppLayout prop={<PaymentTable />} />} />
              <Route
                path="/agents"
                element={
                  <AppLayout prop={<AgentSidebar prop={<AgentTable />} />} />
                }
              />
              <Route
                path="/agents/performance"
                element={
                  <AppLayout prop={<AgentSidebar prop={<ScoreTable />} />} />
                }
              />
              <Route path="/scores" element={<ScoreLayout />} />
              <Route
                path="/verifypayment"
                element={<AppLayout prop={<VerifyPaymentForm />}> </AppLayout>}
              />
              <Route
                path="/payments"
                element={<AppLayout prop={<PaymentTable />}> </AppLayout>}
              />
              <Route
                path="/orders"
                element={<AppLayout prop={<OrderTable />}> </AppLayout>}
              />
              <Route
                path="/returns"
                element={<AppLayout prop={<ReturnTable />} />}
              />
              <Route path="/logs" element={<AppLayout prop={<LogTable />} />} />
              <Route
                path="/calls"
                element={<AppLayout prop={<CallsTable />} />}
              />
              <Route
                path="/shipments"
                element={<AppLayout prop={<ShipmentForm />}> </AppLayout>}
              />
              <Route
                path="/labels"
                element={<AppLayout prop={<LabelsLayout />} />}
              />
              <Route
                path="/logout"
                element={<Logout setLoggedIn={setLoggedIn} />}
              />
            </>
          ) : (
            <Route
              path="*"
              element={
                <Login
                  email={email}
                  password={password}
                  setEmail={setEmail}
                  setPassword={setPassword}
                  handleLogin={handleLogin}
                  role={role}
                  setRole={setRole}
                />
              }
            />
          )}
        </Routes>
      </BrowserRouter>
      {/* Alert Component */}
      <AlertBox />
      <Loader />
      <div>{isMobile && <Blank />}</div>
    </>
  );
};

export default App;
