const today = new Date();

export const todayDate = () => {
  const options = { timeZone: 'America/New_York' };
  const date = today.toISOString('en-US', options)?.split('T')[0];
  return date;
};

export const todayTimestamp = () => {
  const options = { timeZone: 'America/New_York' };
  const date = today.toISOString('en-US', options);
  return date;
}

