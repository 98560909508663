import { useEffect, useState, useContext } from "react";
import "./searchLabel.scss";
import Labels from "./Labels";
import fetchLabels from "../../utils/fetchLabels";
import AlertContext from "../../globals/AlertContext";

const SearchLabel = () => {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { setAlert } = useContext(AlertContext);

  const getLabels = async () => {
    const data = await fetchLabels(startDate, endDate, query);
    setData(data);
    //console.log("getLabels", Array(data));
  };

  useEffect(() => {
    getLabels();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (startDate ^ endDate) {
      setAlert("Enter both Start & End date");
      return;
    }

    const data = await fetchLabels(startDate, endDate, query);
    setData(data);

  };

  return (
    <>
      <div className="searchLabel">
        <h1>Search Labels</h1>
        <form onSubmit={handleFormSubmit}>
          <div>
            <label>Search</label>
            <input
              type="text"
              placeholder="Example: '1139' or 'Return'"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <div>
            <label>Start Date</label>
            <input
              type="text"
              placeholder="YYYY-MM-DD"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div>
            <label>End Date</label>
            <input
              type="text"
              placeholder="YYYY-MM-DD"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>

          <button type="submit" onClick={handleFormSubmit}>
            Search
          </button>
        </form>
      </div>

      {data.length ? <Labels files={data} /> : ""}
    </>
  );
};

export default SearchLabel;
