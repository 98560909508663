export const sidebarNavItems = [
    {
        display: 'Payments',
        icon: <i className='bx bx-dollar'></i>,
        to: '/payments',
        section: 'payments'
    },
    {
        display: 'Orders',
        icon: <i className='bx bx-box'></i>,
        to: '/orders',
        section: 'orders'
    },
    // {
    //     display: 'Verify Payment',
    //     icon: <i className='bx bx-receipt'></i>,
    //     to: '/verifypayment',
    //     section: 'verifypayment'
    // },
   
    // {
    //     display: 'Shipment',
    //     icon: <i className='bx bx-cart'></i>,
    //     to: '/shipment',
    //     section: 'shipment'
    // },
 
    {
        display: 'Returns',
        icon: <i className='bx bx-package'></i>,
        to: '/returns',
        section: 'returns'
    },
    {
        display: 'Labels',
        icon: <i className='bx bx-note'></i>,
        to: '/labels',
        section: 'labels'
    },
    {
        display: 'Calls',
        icon: <i className='bx bx-phone'></i>,
        to: '/calls',
        section: 'calls'
    },
    {
        display: 'Agents',
        icon: <i className='bx bx-user'></i>,
        to: '/agents',
        section: 'agents'
    },
    {
        display: 'Agent Score LIVE',
        icon: <i className='bx bx-trophy'></i>,
        to: '/scores',
        section: 'scores'
    },
    {
        display: 'Server Logs',
        icon: <i className='bx bx-copy'></i>,
        to: '/logs',
        section: 'logs'
    },
    {
        display: 'Logout',
        icon: <i className='bx bx-exit'></i>,
        to: '/logout',
        section: 'logout'
    },
  
]
