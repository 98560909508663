import React, { useState } from 'react';
import axios from 'axios';
import './VerifyPaymentForm.scss';

const VerifyPaymentForm = () => {
  const [orderID, setOrderID] = useState('');
  const [verificationStatus, setVerificationStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const response = await axios.post('https://shielded-mountain-28914.herokuapp.com/api/admin/verifypayment', {
        orderId: orderID,
      });

      const { status } = response.data;

      setVerificationStatus(status);

    } catch (error) {
      console.error(error);
      // Handle error response
    }

    setIsLoading(false);
  };

  return (
    <div className="payment">
      <h1>Verify Payment</h1>
      <form onSubmit={handleFormSubmit}>
        <div>
          <label>Payment ID:</label>
          <input
            type="text"
            value={orderID}
            onChange={(e) => setOrderID(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Verifying...' : 'Verify Payment'}
        </button>
      </form>

      {verificationStatus && (
        <div>
          <h2>Verification Status:</h2>
          <p>{verificationStatus}</p>
          {/* {
            verificationStatus.map(()=>{

            })
          } */}
        </div>
      )}
    </div>
  );
};

export default VerifyPaymentForm;
