import { Outlet } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Spreadsheet from "../../components/spreadsheet/Spreadsheet"
import './AppLayout.scss';

const AppLayout = ({prop}) => {
    return (<div className="Screen" >
        <Sidebar />
        <div className="Screen__content">       
        {(prop && 1) ? prop : <Spreadsheet />}
        </div>
    </div>);
};

export default AppLayout;
