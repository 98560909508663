import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./AgentSidebar.scss";
import { agentSidebarNavItems } from "./agentitems";

const AgentSidebar = ({ prop }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [stepHeight, setStepHeight] = useState(0);
  const agent_sidebarRef = useRef();
  const indicatorRef = useRef();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      const sidebarItem = agent_sidebarRef.current.querySelector(
        ".agent_sidebar__menu__item"
      );
      indicatorRef.current.style.height = `${sidebarItem.clientHeight}px`;
      setStepHeight(sidebarItem.clientHeight);
    }, 50);
  }, []);

  // change active index
  useEffect(() => {
    const curPath = window.location.pathname?.split("/agents")[1];
    //console.log(curPath);
    const activeItem = agentSidebarNavItems.findIndex(
      (item) => item.section === curPath
    );
    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  //console.log(activeIndex);

  return (
    <>
      <div className="agent_sidebar">
        <div className="agent_sidebar__logo">
          {/* <img src = {logo} style={{ margin: "auto", width: "210px"}} alt = 'logo' /> */}
          <h6>Menu</h6>
        </div>
        <div ref={agent_sidebarRef} className="agent_sidebar__menu">
          <div
            ref={indicatorRef}
            className="agent_sidebar__menu__indicator"
            style={{
              transform: `translateX(-50%) translateY(${
                activeIndex * stepHeight
              }px)`,
            }}
          ></div>
          {agentSidebarNavItems.map((item, index) => (
            <Link to={item.to} key={index}>
              <div
                className={`agent_sidebar__menu__item ${
                  activeIndex === index ? "active" : ""
                }`}
              >
                <div className="agent_sidebar__menu__item__icon">
                  {item.icon}
                </div>
                <div className="agent_sidebar__menu__item__text">
                  {item.display}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      {/* <AgentTable />
       */}
      <div className="ml-80"> {prop}</div>
    </>
  );
};

export default AgentSidebar;
