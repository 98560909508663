import axios from "axios";
import {url} from "./server.js";
// import CreateAlert from "../globals/createAlert";


const fetchUser = async () => {
  //console.log(localStorage.getItem("token"));
  try {
    const response = await axios.get(`${url}api/admin/users`,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
    }
    );
    //console.log("Response from fetchUser:", response);
    return response.data.data;
  } catch (error) {
    // CreateAlert("Error creating user");
    console.error("Error fetching users:", error);
    return null;
  }
};

export default fetchUser;
