import React from 'react';
import AlertContext from '../../globals/AlertContext';

const Loader = () => {

  const { showLoader } = React.useContext(AlertContext);


  return (
     showLoader &&  (
    <div className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center h-16 bg-blue-500">
      <span className="text-white loading loading-bars loading-lg"></span>
    </div>
  ));
};

export default Loader;
