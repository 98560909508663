import "./spreadsheet.scss";

const Spreadsheet = () => {

  return(
    <> 
    <div className="Sheet">
      <iframe
        src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSekuvakbbmZaHkc0w-uLTbgZNNBJbTw_2bJ4akKBkEX12SRrL8lSmm3pun_fWA8g1Iv6skbx7Q-EHZ/pubhtml?footer=false"
        width="100%"
        height="100%"
        overflow="hidden"
        title="Embedded Spreadsheet"
      ></iframe>
    </div>
    </>
  );
};

export default Spreadsheet;
