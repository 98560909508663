import React from "react";
import { Navigate } from "react-router-dom";

const Logout = ( {setLoggedIn}) => {
    setLoggedIn(false);
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("token");

    return (
      <div>
        logged out!
        <Navigate to="/" />
      </div>
    );
  };

  export default Logout;