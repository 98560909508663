import React from "react";

const Stats = ({ data }) => {
  const calculateCredit = () => {
    let credit = 0;
    data.forEach((item) => {
      if (item.type === "ORDER" && item.paymentStatus==="COMPLETED") {
        const amount = parseFloat(item.amount);
        if (!isNaN(amount)) {
          credit += amount;
        }
      }
    });
    return credit.toFixed(2);
  };

  const calculateDebit = () => {
    let debit = 0;
    data.forEach((item) => {
      if (item.type === "REFUND") {
        const amount = parseFloat(item.amount);
        if (!isNaN(amount)) {
          debit += amount;
        }
      }
    });
    return debit.toFixed(2);
  };

  const calculateBalance = () => {
    const credit = calculateCredit();
    const debit = calculateDebit();
    return (credit - debit).toFixed(2);
  };

  return (
    <div className="fixed shadow right-12 top-10 stats">
      <div className="stat">
        <div className="stat-figure text-primary"></div>
        <div className="stat-title">Credit</div>
        <div className="stat-value text-primary">{`$ ${calculateCredit()}`}</div>
        <div className="stat-desc">From data shown (in Dollars)</div>
      </div>

      <div className="stat">
        <div className="stat-figure text-secondary"></div>
        <div className="stat-title">Refund</div>
        <div className="stat-value text-secondary">{`- $ ${calculateDebit()}`}</div>
        <div className="stat-desc">From data shown (in Dollars)</div>
      </div>

      <div className="stat">
        <div className="stat-figure text-secondary"></div>
        <div className="stat-title">Sales</div>
        <div className="stat-value">{`$ ${calculateBalance()}`}</div>
        <div className="stat-desc text-secondary">Sales as per data</div>
      </div>
    </div>
  );
};

export default Stats;
