import React, { useState, useEffect } from "react";
import rank1 from "./rank-1.png";
import "./stars.scss";
import Bonus from "../bonus";
import fetchScore from "../../../utils/fetchScore";

const Stars = ({ rating }) => {
  if (rating === 5)
    return (
      <>
        {Array(5)
          .fill()
          .map((_, index) => (
            <input
              key={index}
              type="radio"
              className="bg-yellow-500 rounded-lg pointer-events-none mask mask-star-2"
            />
          ))}
      </>
    );

  if (rating === 0)
    return (
      <>
        {Array(5)
          .fill()
          .map((_, index) => (
            <input
              key={index}
              type="radio"
              className="bg-gray-100 rounded-lg pointer-events-none mask mask-star-2"
            />
          ))}
      </>
    );

  const fill = Number(Math.abs(rating % 1) * 100).toFixed(2);
  const length = Math.floor(rating);
  const left = 4 - length;

  return (
    <>
      {Array(length || 0)
        .fill()
        .map((_, index) => (
          <input
            key={index}
            type="radio"
            className="bg-yellow-500 rounded-lg pointer-events-none mask mask-star-2"
          />
        ))}

      {
        <input
          type="radio"
          className="bg-yellow-500 rounded-lg pointer-events-none mask mask-star-2"
          style={{
            background: `linear-gradient(to right, #EAB308 0%, #EAB308 ${fill}%, white ${fill}%, white 100%)`,
          }}
        />
      }
      {Array(left || 0)
        .fill()
        .map((_, index) => (
          <input
            key={index}
            type="radio"
            className="bg-white rounded-lg pointer-events-none mask mask-star-2"
          />
        ))}
    </>
  );
};

const StarRating = ({ avgRating, totalOrders, length, avgScore }) => {
  return (
    <>
      <div className="flex flex-row items-center justify-between h-20 p-4 bg-teal-600 rounded-lg shadow-xl">
        <div className="flex flex-row justify-center text-xl font-bold text-white w-80">
          <div className="flex flex-row mt-1 rating rating-xl">
            <Stars rating={avgRating} />
          </div>

          <p className="relative flex ml-4 text-3xl">
            {Number(avgRating).toFixed(1)} /{" "}
            <p className="mt-1 ml-1 mr-2 text-2xl"> 5</p> ({totalOrders}){" "}
          </p>
        </div>

        <span className="mr-4 text-2xl font-semibold text-white ">
          AgentScore™ {avgScore}
        </span>
      </div>
    </>
  );
};

const colourCode = (rating) => {
  if (rating >= 4) return "text-green-500";
  if (rating >= 3 && rating < 4) return "text-orange-500";
  if (rating >= 0 && rating < 3) return "text-red-500";
  return "";
};

const colourCodeDelay = (delay) => {
  if (delay >= 8) return "text-red-500";
  if (delay >= 5 && delay < 8) return "text-orange-500";
  if (delay >= 0 && delay < 5) return "text-green-500";
  return "text-gray-500";
};

const ScoreBoard = () => {
  const [agents, setAgents] = useState({});

  const colourCodeScore = (rating) => {
    if (rating >= (agents.avgScore - 10)) return "text-green-500";
    return "text-orange-500";
  };

  const fetchScores = async () => {
    try {
      const response = await fetchScore();
      const data = response;

      let totalOrders = 0;
      let totalRating = 0;
      let totalScore = 0;

      data.forEach((item) => {
        totalOrders += item.orders;
        totalRating += item.rating;
        totalScore += item.score;
      });

      const length = data.length;
      const avgRating = totalRating / length;
      const avgScore = totalScore / length;

      setAgents({
        data: data,
        totalOrders: totalOrders,
        length: length,
        avgRating: avgRating,
        avgScore: avgScore,
      });
      //console.log("agents", agents);
    } catch (error) {
      console.error("Error fetching scores:", error);
    }
  };

  useEffect(() => {
    fetchScores();
  }, []);

  return (
    <div
      className="relative flex flex-col flex-wrap justify-center gap-4 "
      style={{ width: "750px" }}
    >
      <StarRating
        avgRating={agents.avgRating}
        totalOrders={agents.totalOrders}
        length={agents.length}
        avgScore={agents.avgScore}
      />
      {agents?.data?.map((item, index) => (
        <div
          key={item.id}
          className="flex flex-row p-10 bg-white rounded-lg shadow-xl h-60 "
        >
          <div className="flex items-center justify-center ">
            {index + 1 === 1 ? (
              <div className="relative w-24 h-24 mx-auto mb-4 rounded-full bg-yellow-50">
                <div className="absolute inset-0 flex items-center justify-center text-xl font-bold text-white">
                  <img src={rank1} alt="rank1" className="w-24 h-24" />
                </div>
              </div>
            ) : (
              <div className="relative w-24 h-24 mx-auto mb-4 bg-gray-300 border border-yellow-100 rounded-full border-6xl">
                <div className="absolute inset-0 flex items-center justify-center text-5xl font-bold text-white">
                  {index + 1}
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col w-1/3 ml-10 text-left">
            <div className="relative ">
              <h3 className="mb-2 mr-4 text-xl font-semibold">
                {item.agent?.split("@")[0]}
              </h3>
            </div>
            <div className="relative ">
              <h6 className="mb-2 text-xs text-gray-600">
                Bonus: $ {Bonus[index]} / hr
                <br />
                Delay is in seconds*
              </h6>
            </div>

            <div className="relative ">
              <div className="flex flex-row rating rating-sm">
                <p className="relative flex mt-4 text-2xl font-semibold">
                  {Number(item.rating).toFixed(1)} /{" "}
                  <p className="mt-0.5 ml-1 mr-2 text-xl"> 5 ({item.calls})</p>
                </p>
              </div>
            </div>

            <div className="relative">
              <div className="flex flex-row mt-1 rating rating-sm">
                <Stars rating={item.rating} />
              </div>
            </div>
          </div>
          <div className="flex flex-col mr-2">
            <div className="flex flex-row">
              <div className="text-center">
                <p className="w-24 mb-1 text-sm font-semibold text-blue-600">
                  SCORE
                </p>
                <div className="relative mx-auto mb-4 border border-gray-500 rounded-full w-14 h-14">
                  <div
                    className={`absolute inset-0 flex items-center justify-center text-xl font-bold ${colourCodeScore(
                      item.score
                    )}`}
                  >
                    {Math.round(item.score)}
                  </div>
                </div>
              </div>
              <div className="text-center">
                <p className="w-24 mb-1 text-sm text-gray-600">CONV. RATE</p>
                <div className="relative mx-auto mb-4 border border-gray-500 rounded-full w-14 h-14">
                  <div
                    className={`absolute inset-0 flex items-center justify-center text-xl font-bold ${(Number(item.orders / item.tickets) * 100)>70? "text-green-500" : "text-orange-500"}`}
                  >
                    {Number(item.orders / item.tickets) * 100}
                  </div>
                </div>
              </div>
              <div className="text-center ">
                <p className="w-24 mb-1 text-sm text-gray-600">LAST DELAY</p>
                <div className="relative mx-auto mb-4 border border-gray-500 rounded-full w-14 h-14">
                  <div
                    className={`absolute inset-0 flex items-center justify-center text-lg font-bold ${colourCodeDelay(
                      item.lastDelay
                    )}`}
                  >
                    {item.lastDelay}
                  </div>
                </div>
              </div>
              <div className="ml-3 text-center">
                <p className="w-24 mb-1 text-sm text-gray-600">LAST RATING</p>
                <div className="relative mx-auto mb-4 border border-gray-500 rounded-full w-14 h-14">
                  <div
                    className={`absolute inset-0 flex items-center justify-center text-xl font-bold ${colourCode(
                      item.lastRating
                    )}`}
                  >
                    {item.lastRating}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row ">
              <div className="relative w-24 mb-4 text-center h-14">
                <p className="pb-2 mb-1 text-sm border-b-2 border-b-solid border-b-black ">
                  ORDERS
                </p>
                <div className="relative w-24 mb-4 h-14">
                  <div
                    className={
                      item.orders < 2
                        ? "text-red-500 text-center mt-3 text-xl font-bold"
                        : "text-green-500"
                    }
                  >
                    {Math.round(item.orders)}
                  </div>
                </div>
              </div>
              <div className="ml-4 text-center">
                <p className="pb-2 mb-1 text-sm border-b-2 border-b-solid border-b-black">
                  TICKETS
                </p>
                <div className="relative mx-auto mb-4 w-14 h-14">
                  <div
                    className={
                      item.orders < 2
                        ? "text-red-500 text-center mt-3 text-xl font-bold"
                        : "text-green-500"
                    }
                  >
                    {item.tickets}
                  </div>
                </div>
              </div>
              <div className="ml-4 text-center">
                <p className="w-24 pb-2 mb-1 text-sm border-b-2 border-b-solid border-b-black ">
                  AVG. RATING
                </p>
                <div className="relative w-24 mx-auto mb-4 h-14">
                  <div
                    className={`inset-0 flex items-center justify-center mt-3 text-xl font-bold text-gray-500 ${colourCode(
                      item.rating
                    )} `}
                  >
                    {item.rating}
                  </div>
                </div>
              </div>
              <div className="ml-4 text-center">
                <p className="pb-2 mb-1 text-sm border-b-2 border-b-solid border-b-black ">
                  AVG. DELAY
                </p>
                <div className="relative w-24 mx-auto mb-4 h-14">
                  <div
                    className={`inset-0 flex items-center justify-center ${colourCodeDelay(
                      item.delay
                    )} mt-3 text-lg font-semibold`}
                  >
                    {item.delay} Sec
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ScoreBoard;
