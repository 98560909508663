import { useEffect, useState } from "react";
import axios from "axios";

const OrderTable = () => {
  const [orders, setOrders] = useState([]);

  const fetchOrders = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_SERVER}api/admin/orders`
      );
      //console.log("response", response.data);
      const data = response.data.data;
      //console.log("data", data);
      setOrders(data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div
      className="fixed ml-10 mr-10 overflow-y-auto text-sm top-40"
      style={{ height: "500px" }}
    >
      <table className="table h-56 overflow-y-scroll bg-white table-pin-rows ">
        <thead className="text-center ">
          <tr>
            <th className="text-sm">Creation Date</th>
            <th className="text-sm">Ticket Id</th>
            <th className="text-sm">Customer Name</th>
            <th className="text-sm">Customer Number</th>
            <th className="text-sm">State</th>
            <th className="text-sm">Order ID</th>
            <th className="text-sm">Payment Status</th>
            <th className="text-sm">Track Shipment</th>
            <th className="text-sm">Shipping Charges</th>

            {/* <th className="text-sm">Shipping Address</th> */}
            <th className="text-sm">Subtotal</th>
            <th className="text-sm">Tax</th>
            <th className="text-sm">Total</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {orders?.map((order, index) => (
            <tr key={index}>
              <td className="w-40">{order.creationDate}</td>
              <td className="text-blue-700 text-bold">
                  <a
                    href={`https://quickshaver.freshdesk.com/a/tickets/${order.ticketId}`}
                    target="_blank"
                  >
                    {order.ticketId}
                  </a>
                </td>
              <td className="w-40">{order.customerName}</td>
              <td className="w-40">{order.customerNumber}</td>
              <td className="w-60">{order.shippingAddressCity}</td>

              <td className="w-40">{order.shopifyOrderNumber}</td>
              <td className="w-60">{order.paymentStatus}</td>
              <td className="text-blue-700 text-bold">
                  <a
                    href={`${order?.trackingURL}`}
                    target="_blank"
                  >
                    Click
                  </a>
                </td>
              <td className="w-40">$ {order.shipping}</td>
              {/* <td className="w-60">{order.shippingAddress}</td> */}
              <td className="w-60">$ {order.subtotal}</td>
              <td className="w-40">$ {order.tax}</td>
              <td className="w-60">$ {order.total}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default OrderTable;
