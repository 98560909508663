export const agentSidebarNavItems = [
 
    {
        display: 'Add Agents',
        icon: <i className='bx bx-plus'></i>,
        to: '/agents',
        section: 'agents'
    },
    // {
    //     display: 'Today Sessions',
    //     icon: <i className='bx bx-time'></i>,
    //     to: '/agents/session',
    //     section: '/session'
    // },
    {
        display: 'Agents Performance',
        icon: <i className='bx bx-trophy'></i>,
        to: '/agents/performance',
        section: '/performance'
    },
    
]
