import React, { useState, useContext } from "react";
import fetchPayment from "../../utils/fetchPayment";
import AlertContext from "../../globals/AlertContext";
import mailPayment from "../../utils/mailPayment";
import { todayDate } from "../../utils/date";

const SearchTable = ( {setPayments}) => {
  const [date, setDate] = useState("");
  const [transaction, setTransaction] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [agent, setAgent] = useState("");
  // const [date, setDate] = useState(null);
  const { setAlert } = useContext(AlertContext);

  const handleSearch = (e) => {
    e.preventDefault();
    // Perform search/filter logic based on the values in 'date', 'transaction', 'ticketId', 'agent', 'date'
    // Update the table data accordingly
    //console.log("Search:", date, transaction, ticketId, agent, date);
  };

  const fetchPayments = async () => {
    const body ={
      date: date,
      transaction: transaction,
      ticketId: Number(ticketId),
      agent: agent,
    }
    try {
      const response = await fetchPayment(body);
      const data = response;
      //console.log("data", data);
      if(data.length === 0) setAlert("No data found");
      setPayments(data);
    } catch (error) {
      console.error("Error fetching payments:", error);
      setAlert("No data found");
    }
  };

  const todayPayments = async () => {
    const body ={
      date: todayDate(),
      transaction: transaction,
      ticketId: Number(ticketId),
      agent: agent,
    }
    try {
      const response = await fetchPayment(body);
      const data = response;
      //console.log("data", data);
      if(data.length === 0) setAlert("No data found");
      setPayments(data);
    } catch (error) {
      console.error("Error fetching payments:", error);
      setAlert("No data found");
    }
  };
    

  const mailPayments = async () => {
    const body ={
      date: date,
      transaction: transaction,
      ticketId: Number(ticketId),
      agent: agent,
    }
    try {
      setAlert("Mail Sent Successfully!");
      await mailPayment(body);
    } catch (error) {
      console.error("Error fetching payments:", error);
      setAlert("Error Sending Mail");
    }
  };

  return (
    <div>
      <form onSubmit={handleSearch}>
        <table className="table mb-10 bg-white table-pin-rows">
          <thead className="text-center">
            <tr>
              {/* <th className="text-sm">Search Date</th> */}
              <th className="text-sm">Search Date</th>
              <th className="text-sm">Transaction Type</th>
              <th className="text-sm">Ticket ID</th>
              <th className="text-sm">Agent</th>
              <th className="text-sm">⚠️ Action</th>
              <th className="text-sm">⚠️ Action</th>
              <th className="text-sm">⚠️ Action</th>

            </tr>
          </thead>
          <tbody className="text-center">
            <tr className="text-center">
              {/* <td>
                <DatePicker
                  selected={date}
                  onChange={(date) => setDate(date)}
                  className="w-full px-2 py-1 text-sm text-center border "
                  style={{ zIndex: "1000" }}
                  placeholderText="Select Date"
                />
              </td> */}
              <td>
                <input
                  type="text"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  className="w-full px-2 py-1 text-sm text-center border"
                  placeholder="YYYY-MM-DD"
                />
              </td>
              <td>
                <input
                  type="text"
                  value={transaction}
                  onChange={(e) => setTransaction(e.target.value)}
                  className="w-full px-2 py-1 text-sm text-center border"
                  placeholder="ORDER / REFUND"
                />
              </td>
              <td>
                <input
                  type="text"
                  value={ticketId}
                  onChange={(e) => setTicketId(e.target.value)}
                  className="w-full px-2 py-1 text-sm text-center border"
                  placeholder="Ticket ID"
                />
              </td>
              <td>
                <input
                  type="text"
                  value={agent}
                  onChange={(e) => setAgent(e.target.value)}
                  className="w-full px-2 py-1 text-sm text-center border"
                  placeholder="Agent"
                />
              </td>

              <td>
                <button type="submit" className=" btn" onClick={fetchPayments}>
                  SEARCH
                </button>
              </td>
              <td>
                <button type="submit" className="btn" onClick={mailPayments}>
                 MAIL STATEMENT
                </button>
              </td>
              <td>
                <button type="submit" className="btn" onClick={todayPayments}>
                 VIEW TODAY
                </button>
              </td>

            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
};

export default SearchTable;
