import React, { useState } from "react";
import AlertContext from "../../globals/AlertContext.js";

const AlertBox = () => {
  //   const [amount, setAmount] = useState("");
  const { showAlert, alertMessage, closeAlert } = React.useContext(AlertContext);

  //   const handleAmountChange = (e) => {
  //     setAmount(e.target.value);
  //   };

  const handleClose = () => {
    // Handle the form submission here, e.g., make an API request
    // setAlert("closed");
    closeAlert();
  };

  return (
    <>
      {showAlert && (
        <div
          style={{ width: "400px", left: "40%", display: "flex", justifyContent: "space-between", backgroundColor: "#308EFE", borderRadius: "10px", color: "white" }}
          className="fixed alert top-4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="w-6 h-6 stroke-current shrink-0"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <span className="text-lg"> {alertMessage}</span>

          <button className="ml-8 mr-2 text-sm" onClick={handleClose}>
            Close
          </button>
        </div>
      )}
    </>
  );
};

export default AlertBox;
