import { useEffect, useState, useContext } from "react";
import AlertContext from "../../globals/AlertContext";
import fetchPayment from "../../utils/fetchPayment";
import Stats from "./Stats";
import PaymentSearchTable from "./FilterPayments";

const PaymentTable = () => {
  const [payments, setPayments] = useState([]);
  const { setAlert } = useContext(AlertContext);

  const fetchPayments = async () => {
    try {
      const response = await fetchPayment({ data: "none" });
      const data = response;
      //console.log("data", data);

      if (data.length === 0) setAlert("No data found");
      setPayments(data);
    } catch (error) {
      console.error("Error fetching payments:", error);
    }
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  return (
    <>
      <Stats data={payments} />
      <div className="flex flex-col p-10 mt-40 overflow-x-auto text-sm ">
        <PaymentSearchTable setPayments={setPayments} />
        <div
          className="fixed mt-40 overflow-y-auto text-sm "
          style={{ height: "500px", width: "79vw" }}
        >
          <table className="table overflow-x-auto bg-white ">
            <thead className="text-center">
              <tr>
                <th className="text-sm">Date</th>
                <th className="text-sm">Time</th>
                <th className="text-sm">Amount</th>
                <th className="text-sm">Transaction</th>
                <th className="text-sm">Ticket ID</th>
                <th className="text-sm">Agent</th>
                <th className="text-sm">Customer Name</th>
                <th className="text-sm">Customer Contact</th>
                <th className="text-sm">Payment Status</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {payments?.map((item, index) => (
                <tr key={index}>
                  <td className="w-40">{item.date}</td>
                  <td className="w-20">
                    {item?.timestamp?.split("T")[1].split(".")[0]}
                  </td>
                  <td className="w-20 text-left pl-7">$ {item.amount}</td>
                  <td className="w-20">{item.type}</td>
                  <td className="w-20 text-blue-700 text-bold">
                    <a
                      href={`https://quickshaver.freshdesk.com/a/tickets/${item.ticketId}`}
                      target="_blank" rel="noreferrer"
                    >
                      {item.ticketId}
                    </a>
                  </td>
                  <td className="w-40">{item.agent}</td>
                  <td className="w-40">{item.customerName}</td>
                  <td className="w-40">{item.customerNumber}</td>
                  <td className="w-40">{item.paymentStatus}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PaymentTable;
