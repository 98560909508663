import { useEffect, useState, useContext } from "react";
import axios from "axios";
import AlertContext from "../../globals/AlertContext";
import refundPayment from "../../utils/refundPayment";

const ReturnTable = () => {
  const [returns, setReturns] = useState([]);
  const { setAlert } = useContext(AlertContext);
  const [amount, setAmount] = useState(0);

  const handleRefund = async (index) => {
    const paymentId = returns[index].paymentId;
    const ticketId = returns[index].ticketId;

    if (amount<=0 || !amount ) {
      setAlert(`Please enter amount and verify`);
      return;
    }
    if (!paymentId) {
      setAlert(`Payment not found!`);
      return;
    }

   const answer =  window.prompt(`Are returned Items checked carefully? (YES OR NO)`);

    if(answer !== "YES"){
      setAlert(`Please check returned items carefully!`);
      return;
    }

    try {
      const response = await refundPayment(paymentId, amount, ticketId); 
      //console.log("response", response);
      if(response)
      setAlert(`Response: ${response.toString()}`);
    } catch (error) {
      setAlert(`Something went wrong...\n Error: ${error}`);
    }
  };

  const fetchReturns = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_LOCAL_SERVER + "api/admin/returns/"
      );
      const data = response.data.data;
      setReturns(data);
    } catch (error) {
      console.error("Error fetching returns:", error);
      setAlert(error.toString())
    }
  };

  // Fetch returns from API
  useEffect(() => {
    fetchReturns();
  }, []);

  return (
    <>
      <div
        className="mt-40 ml-10 mr-10 overflow-y-auto text-sm "
        style={{ height: "600px" }}
      >
        <table className="table bg-white table-pin-rows ">
          <thead className="text-center">
            <tr>
              <th className="text-sm">Date</th>
              <th className="text-sm">Time</th>
              <th className="text-sm">Total</th>
              <th className="text-sm">Ticket ID</th>
              <th className="text-sm">Order Items</th>
              <th className="text-sm">Reason</th>
              <th className="text-sm">Category</th>
              <th className="text-sm">Track Pickup</th>
              <th className="text-sm">Payment Status</th>
              <th className="text-sm">Refunded Amount</th>
              <th className="text-sm">⚠️ Enter Amount</th>
              <th className="text-sm">⚠️ Action</th>
            
            </tr>
          </thead>
          <tbody className="text-center">
            {returns.map((item, index) => (
              <tr key={index}>
    
                <td className="w-40">{item.date} </td>
                <td>{item?.timestamp?.split("T")[1].split(".")[0]}</td>
                <td className="w-20"> $ {item.orderAmount}</td>
                <td className="text-blue-700 text-bold">
                  {" "}
                  <a
                    href={`https://quickshaver.freshdesk.com/a/tickets/${item.ticketId}`}
                    target="_blank" without rel="noreferrer"
                  >
                    {item.ticketId}
                  </a>
                </td>
                <td> Product 1: {item.returnProduct1} Product 2:  {item.returnProduct2}</td>
                <td className="w-80">{item.returnReason}</td>
                <td className="w-40">{item.returnCategory}</td>
                <td className="text-blue-700 text-bold">
                  <a href={item.trackingURL} target="_blank" without rel="noreferrer">
                    Click
                  </a>
                </td>
                <td>{item.paymentStatus}</td>
                <td>$ {item.refundedAmount}</td>
                <td>
                  <input
                    className="w-20 text-sm text-center border bg-slate-100 h-7"
                    onChange={(e)=>setAmount(Number(e.target.value))}
                    type="text"
                    placeholder="$ Amount"
                  ></input>
                </td>
                <td>
                  <button
                    className="text-xs w-36 btn btn-xs bg-slate-100"
                    onClick={()=>handleRefund(index)}
                  >
                    Initiate Refund
                  </button>
                </td>
            </tr>
            ))}
          </tbody>
          {/* foot */}
          <tfoot></tfoot>
        </table>
      </div>
    </>
  );
};

export default ReturnTable;
