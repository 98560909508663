import React, { useState } from "react";
import "./labels.scss";
import axios from "axios";

const Labels = ({ files }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  // const [data, setData] = useState([]);

  const data = files;

  
  const handleFileSelection = (fileId, fileName) => {
    const isSelected = selectedFiles.some((file) => file.id === fileId);
  
    if (isSelected) {
      setSelectedFiles(selectedFiles.filter((file) => file.id !== fileId));
    } else {
      setSelectedFiles([...selectedFiles, { id: fileId, name: fileName }]);
    }
  };
  

  const handlePrint = async() => {

    try {
      // Send the selected label file list to the server
      const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER}`+`api/admin/labels/download`, { selectedFiles }, { responseType: 'blob' });
  
      // Create a URL object from the response data
      const url = window.URL.createObjectURL(new Blob([response.data]));
  
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'labels.zip');
  
      // Programmatically click the link to start the download
      document.body.appendChild(link);
      link.click();
  
      // Clean up the temporary link element
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading files:', error);
    }
  };

  const handleSelectAll = () => {
    const allFiles = data.map((file) => ({ id: file.id, name: file.name }));
    setSelectedFiles(allFiles);
  };
  
  return (
    <>
      <div className="fixed z-50 flex mt-4 mr-1 right-20 top-10 ">
    
        <button
          className="px-4 py-2 font-semibold text-white bg-blue-500 printButton disabled:bg-gray-400 disabled:cursor-not-allowed"
          onClick={handlePrint}
          disabled={selectedFiles.length === 0}
        >
          {`Download Selected (${selectedFiles?.length})`}
        </button>
        <button
          className="px-4 py-2 ml-10 mr-10 font-semibold text-white bg-blue-500 printButton disabled:bg-gray-400 disabled:cursor-not-allowed"
          onClick={handleSelectAll}
        >
          {`Select All (${data?.length})`}
        </button>
        <a href="https://drive.google.com/drive/folders/1OILlyTTnfirbOSBBpjyFZFYhXj1bN_1d" target="_blank" rel="noreferrer">
        <button
          className="px-4 py-2 font-semibold text-center text-white bg-blue-500 printButton disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          <i className="text-xl bx bx-folder"></i>{`  Manage Folder`}
        </button>
        </a>
      </div>
      <div className="p-4 labels">
        <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ">
          {data.map((file) => (
            <div
              key={file.id}
              className={`fileItem border border-gray-300 bg-white p-4 ${
                selectedFiles.some((selectedFile) => selectedFile.id === file.id && selectedFile.name === file.name) ? "selected" : ""
              }`}
              onClick={() => handleFileSelection(file.id, file.name)}
            >
              <div className="relative">
                <input
                  type="checkbox"
                  className="absolute top-2 left-2"
                  checked={selectedFiles.some((selectedFile) => selectedFile.id === file.id && selectedFile.name === file.name) ? "selected" : ""                }
                  onChange={() => handleFileSelection(file.id, file.name)}
                />
                <img
                  src={`https://drive.google.com/uc?id=${file.id}`}
                  alt={file.name}
                  className="object-contain w-full h-auto mb-2 max-h-48"
                />
              </div>
              <span className="block overflow-hidden font-semibold text-center whitespace-nowrap">
                {file.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Labels;
