import { useEffect, useState, useContext } from "react";
import axios from "axios";
import AlertContext from "../../globals/AlertContext";

const LogTable = () => {
  const [logs, setLogs] = useState([]);
  const { setAlert } = useContext(AlertContext);

  // const handleRefund = (index) => {
  //   setAlert(`Refund initiated successfully`);
  // };

  const fetchLogs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_SERVER}api/admin/logs/`
      );
      //console.log("response", response.data);
      const data = response.data.data;
      //console.log("data", data);
      setLogs(data);
    } catch (error) {
      console.error("Error fetching logs:", error);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  return (
    <>
      <div className="fixed ml-10 mr-10 overflow-y-auto text-sm top-40" style={{height: "500px" }}>
        <table className="table h-56 overflow-y-scroll bg-white table-pin-rows ">
          <thead className="text-center ">
            <tr>
              <th className="text-sm">Date</th>
              <th className="text-sm">Message</th>
              <th className="text-sm">Ticket Id</th>
              <th className="text-sm">Agent</th>
              <th className="text-sm">From </th>
            </tr>
          </thead>
          <tbody className="text-center">
            {logs.map((item, index) => (
              <tr key={index}>
                <td className="w-80">{item.timestamp} </td>
                <td className="">{item.log} </td>
                <td className="w-60">{item.ticketId}</td>
                <td className="w-80">{item.agent}</td>
                <td className="w-60">{item.from}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LogTable;
